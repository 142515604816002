<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Diğer Gelir Tahsilatı</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.collected_on"
              :rules="[rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              label="Tutar"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, 9999999.99),
              ]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-select-collecting
              label="Tahsilat Yeri"
              :rules="[rules.required]"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              required
              :readonly="disabled"
              :filled="disabled"
              can-collect
              is-active
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              label="Belge No"
              v-model="formData.document_no"
              :rules="[rules.maxLength(formData.document_no, 20)]"
              maxlength="20"
              :filled="disabled"
              :readonly="disabled"
            />
          </v-col>
          <v-col>
            <rs-textarea
              :label="$t('labels.description')"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          :hide-save-close="disabled"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["cluster"]),
  },
  data() {
    return {
      id: null,
      otherIncomeId: null,
      disabled: false,
      formData: {
        amount: null,
        bank_account_id: null,
        collected_on: null,
        description: null,
        document_no: null,
        safe_id: null,
      },
    };
  },
  methods: {
    show(id, otherIncomeId, disabled, defaultValues) {
      this.id = null;
      this.otherIncomeId = null;
      this.disabled = false;

      this.formData = Object.assign(
        {
          amount: null,
          bank_account_id: null,
          collected_on: new Date().toJSON().split("T")[0],
          description: null,
          document_no: null,
          safe_id: null,
        },
        defaultValues || {}
      );

      if (disabled) {
        this.disabled = true;
      }

      if (id) {
        this.id = id;
        this.load();
      } else if (otherIncomeId) {
        this.otherIncomeId = otherIncomeId;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`income/payments/${this.id}`)
        .then((response) => {
          this.loadData(response);
          document.getElementsByClassName("formatted-text")[0].innerHTML =
            this.numberToLocaleFormat(this.formData.amount);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      this.$api
        .post(`income/other-incomes/${this.otherIncomeId}/payments`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
