<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Diğer Gelir İşlemleri"
            icon="mdi-cash-refund"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-other-income')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            :show-delete="can('delete-other-income')"
            :delete-enabled="deleteEnabled"
            @click:delete="handleDeleteClick"
            :add-route="
              can('edit-other-income') ? { name: 'incomes.other.create' } : null
            "
            v-bind="titleBarAttrs"
          >
            <template v-slot:buttons:append>
              <v-tooltip bottom v-if="can('edit-other-income')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    @click.prevent="handlePaymentClick"
                    :disabled="selectedItems.length !== 1"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-cash-plus</v-icon>
                  </v-btn>
                </template>

                Tahsilat Oluştur
              </v-tooltip>
            </template>
            <template v-slot:buttons:prepend>
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tür Filtrele"
                :items="typeFilterList"
                v-model="typeFilter"
                @change="handleTypeFilterChange"
                dense
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.other.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense_type="{ item, value }">
          <router-link
            :to="{
              name: 'incomes.other.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster="{ item, value }">
          <router-link
            title="Toplu Yaşam Alanı Tanımına Git"
            v-if="item.cluster_id"
            :to="{
              name: 'definitions.clusters.edit',
              params: { id: item.cluster_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item, value }">
          <router-link
            title="Tedarikçi Tanımına Git"
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.edit',
              hash: '#transactions',
              params: { id: item.provider_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.issues_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_collected="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_remaining="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_collected="{ item }">
          <rs-table-cell-boolean
            :title="
              item.collected_on ? item.collected_on.toLocaleDateString() : null
            "
            :value="item.is_collected"
          />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ item, value }">
          <router-link
            :to="{ name: 'incomes.other.edit', params: { id: item.id } }"
            title="Detay Göster"
          >
            <rs-table-cell-string :limit="20" :value="value" />
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <!-- <template v-slot:item.bank_safe="{ item }">
          <router-link
            v-if="item.bank_account"
            :to="{
              name: 'definitions.bank-accounts.edit',
              hash: '#transactions',
              params: { id: item.bank_account_id },
            }"
          >
            {{ item.bank_account }}
          </router-link>
          <router-link
            v-else-if="item.safe"
            :to="{
              name: 'definitions.safes.edit',
              hash: '#transactions',
              params: { id: item.safe_id },
            }"
          >
            {{ item.safe }}
          </router-link>
        </template> -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item }">
          <router-link
            v-if="item.resident_id"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.resident_id },
            }"
            title="Sakin tanımına git"
          >
            {{ item.resident }}
          </router-link>
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />

      <OtherIncomePaymentForm ref="paymentForm" @saved="loadList" />
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions, isPage } from "@/view/mixins";
import OtherIncomePaymentForm from "@/view/pages/income/forms/OtherIncomePaymentForm";

export default {
  name: "OtherIncomeList",
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  components: {
    OtherIncomePaymentForm,
  },
  props: {
    hideDateFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "positiveExpenseTypeList"]),
    deleteEnabled() {
      if (!this.selectedItems.length) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].amount_collected > 0) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      pageMeta: {
        title: "Diğer Gelir İşlemleri Liste",
      },
      providerList: [],
      typeFilter: null,
      typeFilterList: [
        {
          id: null,
          name: "Tümü",
        },
        {
          id: "interest",
          name: "Faiz - Fon Geliri",
        },
        {
          id: "unknown-sender",
          name: "Yatıranı Belli Olmayan",
        },
        {
          id: "custom-only",
          name: "Diğer Gelirler",
        },
      ],
      options: {
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-other-income"),
        },
        {
          text: "Tarih",
          value: "issues_on",
          searchable: "date",
        },
        {
          text: "Diğer Gelir Türü",
          value: "expense_type",
          searchable: "multiselect",
          options: () => this.positiveExpenseTypeList,
          width: "150px",
          align: "center",
        },
        {
          text: "Belge No",
          value: "document_no",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Tedarikçi",
          value: "provider",
          searchable: "multiselect",
          options: () => this.providerList,
        },
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
        },
        {
          text: "Tahakkuk",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tahsilat",
          value: "amount_collected",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalan",
          value: "amount_remaining",
          searchable: "number",
          align: "end",
        },
        {
          text: "B.Hesabı / Kasa",
          value: "bank_safe",
          searchable: "text",
        },
        {
          text: "Tahsil Edildi",
          value: "is_collected",
          searchable: "yesNo",
        },
      ],
      titleBarAttrs: {
        exportUrl: "income/other-incomes",
        exportParams: this.getParams,
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.$api
        .query("income/other-incomes", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            const list = [];
            for (const index in response.data.data) {
              const item = response.data.data[index];
              if (item.collected_on) {
                item.collected_on = new Date(item.collected_on);
              }
              list.push(item);
            }
            this.list = list;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }

          this.loadProviderList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadProviderList() {
      const params = { per_page: 1000 };

      params.cluster_id = this.clusterId;

      this.$api
        .query(`cluster-providers`, { params })
        .then((response) => {
          this.providerList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "incomes.other.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane diğer geliri silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      const selectedItemIds = this.selectedItems.map((item) => item.id);
      const promises = [];

      this.isLoading = true;

      for (const index in selectedItemIds) {
        const promise = this.delete(selectedItemIds[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$refs.confirmDelete.hide();
        this.loadList();
      });
    },
    delete(id) {
      return this.$api
        .delete(`income/other-incomes/${id}`)
        .then(() => {
          this.$toast.success("Diğer gelir silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleTypeFilterChange(value) {
      if (value === null) {
        this.search.expense_type = null;
        this.search.custom_only = null;
      } else if (value === "interest") {
        this.search.expense_type = [10002];
        this.search.custom_only = null;
      } else if (value === "unknown-sender") {
        this.search.expense_type = [10001];
        this.search.custom_only = null;
      } else if (value === "custom-only") {
        this.search.expense_type = null;
        this.search.custom_only = true;
      } else {
        return;
      }

      this.loadList();
    },
    handlePaymentClick() {
      if (this.selectedItems.length !== 1 || this.isLoading) {
        return false;
      }

      if (!this.selectedItems[0].amount_remaining) {
        this.$toast.warning("Ödenmiş bir diğer gelire tahsilat ekleyemezsiniz");
        return false;
      }

      this.$refs.paymentForm.show(null, this.selectedItems[0].id, null, {
        amount: this.selectedItems[0].amount_remaining,
      });
    },
  },
};
</script>
